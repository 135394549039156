<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <h2>Olá {{ user.nome }}</h2>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="4"
            offset-md="4"
          >
            <v-btn
              color="primary"
              class="mr-4"
              to="/promotor/novos-revendedores"
              block
            >
              NOVO REVENDEDOR(A)
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
          >
            <v-btn
              color="primary"
              class="mr-4"
              to="/promotor/dashboard"
              outlined
              block
            >
              MINHA DASHBOARD
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
      >
        <v-simple-table>
          <thead>
            <tr>
              <th>Id Cliente</th>
              <th>Nome</th>
              <th>CPF</th>
              <th>Telefone (SMS)</th>
              <th>Status da Analise</th>
              <th>Visualizar</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in items"
              :key="item.id"
            >
              <td>{{ item.cliente.id }}</td>
              <td>{{ item.cliente.nome }}</td>
              <td>{{ item.cliente.cpf }}</td>
              <td>{{ item.cliente.contatoSMS }}</td>
              <td>
                <v-chip
                  :color="getColor(item.requestStatusId)"
                  dark
                >
                  {{ item.requestStatusTexto || 'Sem Analise' }}
                </v-chip>
              </td>
              <td>
                <v-chip
                  color="primary"
                  dark
                  :to="`/promotor/revendedor/${item.cliente.id}`"
                >
                  <v-icon small>
                    mdi-eye
                  </v-icon>
                </v-chip>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { StatusEnum } from '../../solicitacoes-credito/status.enum'

  export default {
    data: () => ({
      items: [],
      item: {},
      files: {
        selfieComDocumento: null,
        selfie: null,
        documento: null,
        comprovanteResidencia: null,
        allFilesFilled: false,
      },
    }),

    computed: {
      ...mapGetters(['user']),
    },

    mounted () {
      this.loadData()
    },

    methods: {
      loadData () {
        this.$http.get('promotor/revendedor')
          .then(({ data }) => {
            this.items = data
          })
      },

      getColor (statusId) {
        switch (statusId) {
          case StatusEnum.EmAnalise:
            return 'warning'
          case StatusEnum.Aprovado:
            return 'success'
          case StatusEnum.Recusado:
            return 'error'
          default:
            return 'primary'
        }
      },
    },
  }
</script>
